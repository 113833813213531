import React from "react";
import "./styles.css";

/**
 * Reusable pagination component
 * 
 * @param {Object} props - Component props
 * @param {number} props.currentPage - Current page number (starts at 1)
 * @param {number} props.totalItems - Total number of items across all pages
 * @param {number} props.itemsPerPage - Number of items displayed per page
 * @param {Function} props.onPageChange - Function called when page changes
 * @param {Function} props.onItemsPerPageChange - Function called when items per page changes
 * @returns {JSX.Element} - Pagination component
 */
export default function Pagination({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange
}) {
  // Calculate page info
  const startItem = totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);
  
  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    const value = parseInt(e.target.value) || 10; // Default to 10 if not a valid number
    onItemsPerPageChange(value);
  };

  return (
    <div className="pagination-container border-t border-gray-200 px-4 py-3 flex items-center justify-between sm:px-6 bg-white">
      <div className="flex-1 flex justify-start">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{startItem}</span> to{" "}
          <span className="font-medium">{endItem}</span>{" "}
          of <span className="font-medium">{totalItems}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-end items-center">
        <div className="mr-4 flex items-center">
          <span className="mr-2 text-sm text-gray-700">Per Page:</span>
          <input
            type="number"
            min="1"
            max="100"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="items-per-page-input rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-16 text-center"
          />
        </div>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
            currentPage === 1
              ? "bg-gray-100 text-gray-400 cursor-not-allowed"
              : "bg-white text-gray-700 hover:bg-gray-50"
          } mr-2`}
        >
          Previous
        </button>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage * itemsPerPage >= totalItems}
          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
            currentPage * itemsPerPage >= totalItems
              ? "bg-gray-100 text-gray-400 cursor-not-allowed"
              : "bg-white text-gray-700 hover:bg-gray-50"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};